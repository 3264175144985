import { useAuthStore } from "@solvari/common-fe/composables";
import { initSentryRoot } from "@solvari/common-fe/integrations";

import { getEnv } from "@solvari/utils";

initSentryRoot({
  addData: () => {
    const authStore = useAuthStore();

    return {
      tags: {
        proId: authStore.user?.pro?.uuid || "",
        proLocale: authStore.user?.pro?.locale || getEnv().config.localeCode,
        userId: authStore.user?.id,
      },
      contexts: {
        user: {
          roles: authStore.user?.roles || [],
          permission: authStore.user?.permissions,
        },
      },
    };
  },
  project: "customer-fe",
});
